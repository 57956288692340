import Transposer from './transposer'

(() => {
  const N_KEYS = 12

  const transposer = new Transposer()
  let searchParams
  let transposedBy

  const transposeChord = function(e, interval) {
    return transposer.transposeChord($(e).data('name'), interval)
  }

  const transposeSong = function(interval) {
    $('[data-chord]').each(function() {
      return $(this).attr('data-chord', transposeChord(this, interval))
    })
    $('.measure-chord, .chord').each(function() {
      $(this).text(transposeChord(this, interval))
    })
    $('.transpose-song').find('.transposed-by').text(transposedBy == 0 ? '' : transposedBy)
    updateQueryString(interval)
  }

  const updateQueryString = function(interval) {
    if (transposedBy == 0) {
      searchParams.delete('transposeBy')
    } else {
      searchParams.set('transposeBy', interval)
    }
    window.history.replaceState(null, null, '?' + searchParams.toString())
  }

  $(document).on('click', '.transpose-song button[name=half_step]', function(e) {
    const step = parseInt($(e.target).attr('data-step'))
    transposedBy += step
    if (Math.abs(transposedBy) === N_KEYS) {
      transposedBy = 0
    }
    transposeSong(transposedBy)
  })

  $(document).on('turbolinks:load', function() {
    searchParams = new URLSearchParams(window.location.search)
    transposedBy = parseInt(searchParams.get('transposeBy'), 10) || 0

    if (transposedBy !== 0) {
      transposeSong(transposedBy)
    }
  })
})()
