$(document).on('turbolinks:load', function() {
  let fileProgress = {}
  const progressBar = $('.progress-bar')

  function updateProgressBar() {
    let progress = 0
    let fileCount = 0
    for (id in fileProgress) {
      progress += fileProgress[id]
      fileCount++
    }
    const percentComplete = fileCount === 0 ? 0 : progress / fileCount
    progressBar.width(`${percentComplete}%`)
  }

  $(document).on('direct-uploads:start', function() {
    progressBar.width(0)
  })

  $(document).on('direct-upload:initialize', function(event) {
    const { id } = event.detail

    fileProgress[id] = 0
  })

  $(document).on('direct-upload:progress', function(event) {
    const { id, progress } = event.detail

    fileProgress[id] = progress
    updateProgressBar()
  });
});
