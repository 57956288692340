$(document).on('turbolinks:load', function () {
  if ($('#share-link').length === 0) return

  const shareButton = $('#share-link-button')
  shareButton.tooltip({ title: shareButton.data('original-title') })

  $(document).on('click', '#share-link-button', function() {
    const copyText = $('#share-link input').get(0)
    copyText.select()
    copyText.setSelectionRange(0, 99999) /* For mobile devices */
    document.execCommand('copy')

    var tooltip = Tooltip.getInstance('#share-link-button')
    tooltip.setContent({ '.tooltip-inner': 'Copied!' })

    const shareButton = $(this)
    $(this).on('hidden.bs.tooltip', function() {
        tooltip.setContent({ '.tooltip-inner': shareButton.data('original-title') })
      })
  })

  $(document).on('shown.bs.modal', '#qr-modal', function() {
    let qrContainer = $('.modal-body canvas').get(0);
    qrContainer.innerHTML = '';
    const shareLink = $('#share-link input').val();

    QRCode.toCanvas(qrContainer, shareLink, {
        width: 320,
        height: 320,
        color: {
          light: '#FAFAFA'
        }
    }, function (error) {
        if (error) console.error(error);
    });
});

  $(document).on('change', '#playlist_allow_anyone', function() {
    $(this).closest('form').trigger('submit')
  })
})
