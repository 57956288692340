(() => {
  $(document).on('ajax:success', '#attachments-list', function(event) {
    const listItem = $(event.target).closest('.list-group-item')
    listItem
      .fadeTo('fast', 0)
      .slideUp('fast', function () {
        this.remove()
      })
  })
})()
