import Transposer from './transposer'

const ComposeToolbar = () => {
  const DEFAULT_TOOLBAR_ITEMS = [['[]', '[ ]'], ['#', '#'], ['b', 'b'], ['/', '/']]
  const CHORD_PATTERN = /\[(\S+?)\]/g

  const toolbar = $('#compose-toolbar')
  const textarea = $("#compose-song textarea")
  const transposer = new Transposer()

  function buildToolbar() {
    const container = toolbar.find('#compose-toolbar-chords')
    container.empty()
    const toolbarItems = chordToolbarItems().concat(DEFAULT_TOOLBAR_ITEMS)
    toolbarItems.forEach((item) => {
      const tooltip = item[0] == '[]' ? 'Insert new chord' : `Insert ${item[1]}`
      const btn = `<button type="button" class="btn btn-outline-secondary" data-item="${item[0]}" title="${tooltip}">${item[1]}</button`
      container.append(btn)
    })
  }

  function chordToolbarItems() {
    return detectChords().map(c => [`[${c}]`, c])
  }

  function detectChords() {
    const text = textarea.val()
    if (!text) return []

    const matches = [...text.matchAll(CHORD_PATTERN)]
    return transposer.detectChords(matches.map(m => m[1]))
  }

  function transposeChords(interval) {
    const text = textarea.val()
    if (!text) return

    const transposed = text.replace(CHORD_PATTERN, function(match, p1) {
      if (transposer.detectChords([p1]).length > 0) {
        const chord = transposer.transposeChord(p1, interval)
        return `[${chord}]`
      } else {
        return match
      }
    })
    const element = textarea.get(0)
    element.focus()
    element.select()
    insertItem(transposed)
    buildToolbar()
  }

  function insertItem(item) {
    const element = textarea.get(0)
    element.focus()
    insertText(element, item)
    if (item === '[]') {
      element.selectionEnd = element.selectionEnd - 1
    }
    element.selectionStart = element.selectionEnd
  }

  toolbar.on('click', 'button[data-item]', function(e) {
    insertItem($(e.target).attr('data-item'))
  })

  toolbar.on('click', 'button[data-step]', function(e) {
    transposeChords(parseInt($(e.target).attr('data-step')))
  })

  textarea.on('paste keyup', buildToolbar)

  buildToolbar()
}

function insertText(element, text) {
  if (!document.execCommand('insertText', false, text)) {
    element.setRangeText(
      text,
      element.selectionStart,
      element.selectionEnd,
      'select'
    )
  }
}

$(document).on('turbolinks:load', function() {
  if ($('#compose-toolbar').length) {
    ComposeToolbar()
  }
});
