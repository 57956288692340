(() => {
  function adjustWidthOfMeasures() {
    $('.chord-progression').each(function() {
      var measure_count = $(this).find('.measure').length
      var width = (100 / measure_count).toFixed(2) + '%'
      $(this).find('.measure').css('width', width)
    })
  }

  $(document).on('turbolinks:load', function() {
    adjustWidthOfMeasures()
  })

  $(document).on('content:loaded', '#preview-song', function() {
    adjustWidthOfMeasures()
  })
})()
