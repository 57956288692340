import 'jquery'
import 'bootstrap'

import 'core-js/stable'
import 'data-confirm-modal.js'
import 'fullscreen-polyfill'
import 'jquery-duration-entry.js'
import 'regenerator-runtime/runtime'
import './common'
import './songs'
import './playlists'

import { Spinner } from 'spin.js'
import * as Ladda from 'ladda'
import Moment from 'moment'
import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import { Tooltip } from 'bootstrap'
import QRCode from 'qrcode'

window.Ladda = Ladda
window.moment = Moment
window.Spinner = Spinner
window.Tooltip = Tooltip
window.QRCode = QRCode

Rails.start()
Turbolinks.start()
ActiveStorage.start()
