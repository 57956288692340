import { Chord, Transposer as _Transposer } from 'chord-transposer'

type Token = typeof Chord | string;

class Transposer {
  detectChords(candidates: string[]) {
    const transposer = new _Transposer(candidates.join(' '))
    const chords = transposer.tokens[0].
      filter((t: Token) => t instanceof Chord).
      map((t: Token) => t.toString()
    )
    return Array.from(new Set(chords))
  }

  transposeChord(chord, interval) {
    return new _Transposer(chord).up(interval).toString()
  }
}

export default Transposer
