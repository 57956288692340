(() => {
  const withHttp = url => !/^https?:\/\//i.test(url) ? `http://${url}` : url;

  $(document).on('change', 'input[name=url]', function() {
    if ($('.import.new').length === 0) return

    // Automatically add scheme to url if missing
    if ($(this).val().length > 0) {
      $(this).val(withHttp($(this).val()))
    }
  })
})()
