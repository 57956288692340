class PreviewPanel {
  constructor() {
    this.panel = $('#preview-song .panel-body')
    this.textarea = $('#compose-song textarea')
    $('#preview-tab')
      .on('show.bs.tab', (e) => {
        this.empty()
        return this.setMinHeight()
      })
      .on('shown.bs.tab', (e) => {
        return this.loadContent()
      })
      .on('hidden.bs.tab', (e) => {
        return this.empty()
      })
  }

  setMinHeight() {
    return this.panel.css('min-height', () => {
      return this.textarea.height()
    })
  }

  startSpinner() {
    const options = {
      scale: 0.8,
      color: '#666',
      position: 'relative',
      top: this.panel.height() / 2 + 'px',
    }
    this.spinner = new Spinner(options).spin()
    return this.panel.append($(this.spinner.el))
  }

  stopSpinner() {
    return this.spinner.stop()
  }

  loadContent() {
    const body = {
      song: {
        content: this.textarea.val(),
      },
    }
    this.startSpinner()
    return $.post('/songs/preview', body, (data) => {
      const $div = $('<div class="song">')
      $('<pre>').html(data).appendTo($div)
      this.panel.append($div)
      return this.panel.trigger('content:loaded')
    }).always(() => {
      return this.stopSpinner()
    })
  }

  empty() {
    return this.panel.empty()
  }
}

$(document).on('turbolinks:load', function() {
  if ($('#preview-song').length) {
    new PreviewPanel()
  }
});
