(() => {
  function includesText(elem, text) {
    return elem.text().toUpperCase().includes(text.toUpperCase())
  }

  function equalsText(elem, text) {
    return elem.text().trim() === text
  }

  function searchSongs() {
    const query = $('form#search').find('input#query').val()
    const selectedKey = $('form#search').find('select#key_signature').val()
    $('table#song-list').find('tbody tr').each(function() {
      const row = $(this)
      const title = row.find('.song-list-title')
      const artist = row.find('.song-list-artist')
      const key = row.find('.song-list-key')
      if (
        (includesText(title, query) || includesText(artist, query)) &&
        (selectedKey.length === 0 || equalsText(key, selectedKey))
      ) {
        row.show()
      } else {
        row.hide()
      }
    })

    const footer = $('table#song-list').find('tfoot')
    if ($('table#song-list tbody tr:visible').length === 0) {
      footer.removeClass('d-none')
    } else {
      footer.addClass('d-none')
    }
  }

  $(document).on('input', 'form#search', function() {
    searchSongs()
  })

  $(document).on('turbolinks:load', function() {
    if ($('form#search').length > 0) searchSongs()
  })
})()
