(() => {
  let timeoutId
  let wheelTimeoutId
  let paused = false
  let speed = 1.0
  let scrollYPos = 0.0

  const baseSpeed = 1.0

  $(document).on('click', '#autoscroll', function() {
    if (timeoutId) {
      stopScroll()
    } else if (!isEndOfPage()) {
      startScroll()
    }
  })

  $(document).on('click', '#autoscroll-speed', function() {
    $('#autoscroll-speed-container').slideToggle('fast')
    $('#autoscroll-speed').toggleClass('active')
    $('#fullscreen-font-size-container').hide()
    $('#fullscreen-font-size').removeClass('active')
  })

  $(document).on('input', '#autoscroll-speed-range', function() {
    speed = parseFloat($(this).val())
    $('#autoscroll-speed').find('sup').text('x' + parseFloat(speed.toFixed(1)))
  })

  $(document).on('wheel', function() {
    pauseScroll()

    window.clearTimeout(wheelTimeoutId)
    wheelTimeoutId = setTimeout(function() {
      resumeScroll()
    }, 50)
  })

  $(document).on('touchstart', function() {
    pauseScroll()
  })

  $(document).on('touchend', function() {
    if (paused) {
      disableMomentumScroll(() => {
        resumeScroll()
      })
    }
  })

  // Disable momentum scroll on iOS
  function disableMomentumScroll(onComplete) {
    $(window).css({
      overflow: 'hidden'
    })
    window.clearTimeout(wheelTimeoutId)
    wheelTimeoutId = setTimeout(function() {
      $(window).css({
        overflow: 'auto'
      })
      onComplete()
    }, 50)
  }

  function startScroll() {
    scrollYPos = $(window).scrollTop()
    scroll()
    $('#autoscroll').addClass('active')
  }

  function stopScroll() {
    clearTimeout(timeoutId)
    timeoutId = null
    $('#autoscroll').removeClass('active')
  }

  function pauseScroll() {
    paused = true
  }

  function resumeScroll() {
    paused = false
    scrollYPos = $(window).scrollTop()
  }

  function scroll() {
    if (!paused) {
      $(window).scrollTop(scrollYPos)
      scrollYPos = scrollYPos + (speed * baseSpeed)
    }
    timeoutId = setTimeout(scroll, 50)
    if (isEndOfPage()) {
      stopScroll()
    }
  }

  function isEndOfPage() {
    return $(window).scrollTop() + $(window).height() >= $('#fullscreen-toolbar-end').offset().top
  }
})()
