(() => {
  let songContent
  let currentFontSize

  $(document).on('click', '#fullscreen-font-size', function() {
    $('#fullscreen-font-size-container').slideToggle('fast')
    $('#fullscreen-font-size').toggleClass('active')
    $('#autoscroll-speed-container').hide()
    $('#autoscroll-speed').removeClass('active')
  })

  $(document).on('input', '#fullscreen-font-size-range', function() {
    fontScale = parseFloat($(this).val())
    setTimeout(function() {
      songContent.css('font-size', (parseInt(currentFontSize) * fontScale) + 'px')
    }, 20)
  })

  $(document).on('turbolinks:load', function() {
    songContent = $('.song-content')
    currentFontSize = songContent.css('font-size')
  })
})()
